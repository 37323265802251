import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router';

import { Layout } from './modules/Layout';
import { Unauthorized } from './modules/Unauthorized';
import { PageDoesntExist } from './modules/common/PageDoesntExist';
import { JobDetail } from './modules/hpcJob/jobDetails/JobDetail';
import { SubmissionForm } from './modules/hpcJob/jobSubmission/SubmissionForm';
import { Listing } from './modules/listing/Listing';
import { JobDetail as SessionDetail } from './modules/remoteDesktop/JobDetail';
import { Session } from './modules/remoteDesktop/Session';
import { SubmissionForm as RemoteSubmissionForm } from './modules/remoteDesktop/SubmissionForm';
import Settings from './modules/settings/Settings';
import { remoteRoutes } from './remoteModules/RemoteRoutes';
import { initRemotes, remotes } from './store/remotes';
import { SwwcLoadingIndicator } from './swwc';
import { addPrecedingSlash, formatBaseUrl } from './utils/formatters';
import { initializeAwsRum } from './utils/rumInitializer';

/**
 * Defines the routing for the application.
 *
 *  **Adding a Remote Component**
 * A remote component (i.e. a microfrontend) can be added either by importing RemoteComponent (react)
 * or RemoteModule (web component) from RemoteModule.jsx and then adding a new route for the component,
 * e.g:
 *  <Route path="systems" element={<RemoteComponent path="systems/CatalogueView" />} />
 *
 */
function App() {
   useEffect(() => {
      initializeAwsRum();
      initRemotes();
   }, []);

   // NOTE - when logging in the hpcJob part of the URL would be lost. We should eventually fix this
   if (window.location.pathname.includes('jobmanager/newjob')) {
      const uri = `${import.meta.env.VITE_DOMAIN}${formatBaseUrl(import.meta.env.BASE_URL)}hpcJob`;
      console.warn(`Redirecting from ${window.location.href} to ${uri}`);
      window.location.href = uri;
      return null;
   }
   if (window.location.origin.includes('poweroncloud')) {
      const uri = `${import.meta.env.VITE_DOMAIN}${addPrecedingSlash(import.meta.env.BASE_URL)}`;
      console.warn('Redirecting from poweroncloud to ', uri);
      window.location.href = uri;
      return null;
   }

   if (!remotes.value) {
      return (
         <div className="flex items-center justify-center h-screen w-screen text-3xl">
            <SwwcLoadingIndicator i18nLoading="Loading Apps" />
         </div>
      );
   }

   return (
      <BrowserRouter basename={import.meta.env.BASE_URL}>
         <Routes>
            <Route element={<Layout />}>
               <Route index element={<Listing />} />
               {remoteRoutes(remotes.value)}
               <Route path="settings" element={<Settings />} />
               <Route path="hpcJob">
                  <Route index element={<SubmissionForm />} />
                  <Route path=":resourceId" element={<JobDetail />} />
               </Route>
               <Route path="remoteDesktop">
                  <Route index element={<RemoteSubmissionForm />} />
                  <Route path=":sessionId" element={<SessionDetail />} />
               </Route>
               <Route path="*" element={<PageDoesntExist />} />
            </Route>
            <Route path="remoteDesktop/:sessionId/session" element={<Session />} />
            <Route path="samauth/login" element={<Navigate to="/" replace />} />
            <Route path="unauthorized" element={<Unauthorized />} />
         </Routes>
      </BrowserRouter>
   );
}

export default App;
