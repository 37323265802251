import { computed, signal } from '@preact/signals-core';

import { hasCredits } from '@/utils/comparators';
import { dataStates } from '@/utils/constants';

export const initialCredits = {
   credits: null,
   dataState: dataStates.loading,
};

export const creditStore = signal(initialCredits);

export const hasBalance = computed(() => hasCredits(creditStore.value));
