// @ts-nocheck
import {
   __,
   compose,
   curry,
   defaultTo,
   either,
   endsWith,
   filter,
   find,
   flatten,
   flip,
   head,
   includes,
   isNil,
   last,
   mergeLeft,
   objOf,
   path,
   pathOr,
   pipe,
   prop,
   propEq,
   propOr,
   startsWith,
   values,
   where,
} from 'ramda';

import { dataStates } from './constants';

export const findByResourceId = compose(find, propEq(__, 'resourceId'));

const getTaskNameByExt = compose(find, where, objOf('filename'), endsWith);

const combinator = curry((f, g, x, y) => g(f(x)(y)));

export const getFilename = combinator(getTaskNameByExt, prop('filename'));

export const getTask = compose(defaultTo({}), head, propOr([], 'tasks'));
export const getTaskName = compose(prop('name'), getTask); // TEST
export const getTaskId = compose(prop('id'), getTask);

export const getBalance = propOr('N/A', 'balance');

const taskStatus = (key) => compose(prop(key), last, pathOr([], ['tasks']));

export const getStatus = either(taskStatus('unifiedStatus'), prop('status'));

export const getFileFromTarget = path(['target', 'files', 0]);

export const findFromKey = (key) => find(propEq(key, 'key'));
export const filterFromKey = (key) => filter(propEq(key, 'key'));

export const getReadableFileSize = (sizeInBytes) => {
   if (sizeInBytes <= 0) {
      return '0';
   }
   const siThreshold = 1000; // SI units are in 1000 not 1024 blocks
   const siUnits = ['B', 'kB', 'MB', 'GB'];
   const unitIndex = Math.min(siUnits.length - 1, Math.floor(Math.log(sizeInBytes) / Math.log(siThreshold)));
   const sizeInUnits = (sizeInBytes / Math.pow(siThreshold, unitIndex)).toFixed(unitIndex === 0 ? 0 : 2);
   return `${sizeInUnits} ${siUnits[unitIndex]}`;
};

export const getJsonWithStatus = (r) => r.json().then(mergeLeft({ httpStatus: r.status }));

export const getDisplayedCost = pathOr(0, ['debitReport', 'amount']);
export const getTemplateByMarketingVersion = compose(find, propEq(__, 'marketingVersion'));

export const getRDVersion = pathOr('', ['remote_desktop', 0, 'marketingVersion']);
export const getJobType = path(['jobDefinition', 'jobType']);
export const getJobTypeAndResourceId = (job) => ({ resourceId: job.resourceId, jobTypeName: getJobType(job) });

export const getHourlyCreditRate = (templates, detailName) => {
   if (isNil(templates) || isNil(detailName)) {
      return 0;
   }
   // Find the object in template with the matching name
   const computeType = find(
      pipe(prop('name'), (name) => includes(name, detailName)),
      flatten(values(templates)),
   );

   // Return the hourlyCreditRate if found, otherwise return 0
   return propOr(0, 'hourlyCreditRate', computeType);
};

export const getTemplateByClusterName = (name) => find(compose(flip(startsWith)(name), prop('name')));

export const getConfigurationDisplay = (dataState) => (template) => {
   if (dataState === dataStates.loading || isNil(template)) {
      return { key: 'common.loading', options: null };
   }

   return { key: 'remote.form.submissionTemplate.value', options: { rate: template.hourlyCreditRate } };
};

export const getRuntime = pathOr(0, ['debitReport', 'runTime']);
export const getUserConsumptionRate = pathOr(0, ['user', 'consumptionRate']);
