import { effect } from '@preact/signals-core';

import { dataStates, pollDelay } from '@/utils/constants';

import { creditActions } from './actions';
import { creditStore } from './state';

export const setupPolling = (client) => {
   effect(() => {
      if (creditStore.value.dataState === dataStates.ready) {
         const timer = setTimeout(() => creditActions.loadCredits(client, true), pollDelay);
         return () => clearTimeout(timer);
      }
   });
};
