// @ts-nocheck
/* eslint-disable max-lines */
import failedIconUrl from '/img/failed-icon.svg';
import hpcJobIcon from '/img/hpcJob.svg';
import remoteDesktopJobIcon from '/img/remoteDesktopJob.svg';
import successIconUrl from '/img/success-icon.svg';
import warningIconUrl from '/img/warning-icon.svg';

export const headers = {
   select: {
      label: 'select',
      path: [],
      type: 'string',
   },
   type: {
      label: 'type',
      path: ['jobDefinition', 'jobType'],
      type: 'string',
   },
   name: {
      label: 'name',
      path: ['name'],
      type: 'string',
   },
   version: {
      label: 'version',
      path: ['jobDefinition', 'applications', 0],
      type: 'string',
   },
   status: {
      label: 'status',
      path: ['status'],
      pathRemote: ['status'],
      type: 'string',
   },
   cost: {
      label: 'cost',
      path: ['debitReport', 'amount'],
      type: 'number',
   },
   submitted: {
      label: 'submitted',
      path: ['submissionTime'],
      type: 'date',
   },
   runTime: {
      label: 'runTime',
      path: ['debitReport', 'runTime'],
      type: 'number',
   },
   quickActions: {
      label: 'quickActions',
      path: [],
      type: 'strings',
   },
};

export const fileTableHeaders = {
   download: {
      label: 'download',
      path: [],
      type: 'string',
   },
   filename: {
      label: 'filename',
      path: ['filename'],
      type: 'string',
   },
   type: {
      label: 'type',
      path: ['type'],
      type: 'string',
   },
   size: {
      label: 'size',
      path: ['size'],
      type: 'string',
   },
};

// Job Steps
export const steps = ['creatingJob', 'uploadingFiles', 'submittingJob', 'computeCluster', 'running', 'finished'];
// Job status:
export const computeJobStatus = {
   Pending: 'PENDING',
   Queued: 'QUEUED',
   Running: 'RUNNING',
   Done: 'DONE',
   Interrupted: 'INTERRUPTED',
   Failed: 'FAILED',
   ServiceTerminated: 'SERVICE_TERMINATED',
   TimedOut: 'TIMEDOUT',
   InsufficientCredits: 'INSUFFICIENT_CREDIT',
};
// Unified task status:
export const computeTaskUnifiedStatus = {
   Error: 'ERROR', // Typically only used internally for jobs with no task
   Pending: 'PENDING',
   Queued: 'QUEUED',
   Starting: 'STARTING',
   Executing: 'EXECUTING',
   Complete: 'COMPLETE',
   Failed: 'FAILED',
   Terminating: 'TERMINATING',
   Terminated: 'TERMINATED',
   StartFailed: 'START_FAILED',
   Interrupted: 'INTERRUPTED',
   Deleting: 'DELETING',
   LaunchPending: 'LAUNCH_PENDING',
   Deleted: 'DELETED',
   Creating: 'CREATING',
   Uploading: 'UPLOADING',
   Submitting: 'SUBMITTING',
   InsufficientCredits: 'INSUFFICIENT_CREDIT',
};

export const jobTypeKeys = {
   hpc: 'cloud_hpc',
   remote: 'remote_desktop',
};

// Applications
export const applications = {
   StarCCMp: 'StarCCMp',
};

// Polling delay
export const pollDelay = 15000;

//Downloading delay
export const downloadDelay = 500;

// Retry Number
export const retryNb = 5;

export const jobIcons = {
   [computeTaskUnifiedStatus.Complete]: successIconUrl,
   [computeTaskUnifiedStatus.Terminating]: warningIconUrl,
   [computeTaskUnifiedStatus.Terminated]: warningIconUrl,
   [computeTaskUnifiedStatus.Failed]: failedIconUrl,
   [computeTaskUnifiedStatus.StartFailed]: failedIconUrl,
   [computeTaskUnifiedStatus.Pending]: warningIconUrl,
   [computeTaskUnifiedStatus.Queued]: warningIconUrl,
   [computeTaskUnifiedStatus.Starting]: warningIconUrl,
   [computeTaskUnifiedStatus.Executing]: warningIconUrl,
   [computeTaskUnifiedStatus.Interrupted]: warningIconUrl,
   [computeTaskUnifiedStatus.InsufficientCredits]: failedIconUrl,
};

// Errors types
export const errorTypes = {
   insufficientCredits: 'CJM_UserHasInsufficientCreditToSubmitJob',
   abortError: 'AbortError',
   uploadFilesError: 'uploadFilesError',
};

export const dataStates = {
   loading: 'LOADING',
   ready: 'READY',
   error: 'ERROR',
   migrating: 'MIGRATING',
};

export const linesToFetch = 100;
export const cursorDefault = btoa(0);
export const lineHeight = 18;
export const INITIAL_PAGE = 0;
export const PAGINATION_THRESHOLD = 20;
export const FILES_PAGINATION_THRESHOLD = 200;
export const quickActions = {
   delete: {
      id: 'delete',
      icon: 'cmdDelete16',
   },
   terminate: {
      id: 'terminate',
      icon: 'cmdStop16',
   },
   monitor: {
      id: 'monitor',
      icon: 'cmdLaunchSimulationTool24',
   },
   connect: {
      id: 'connect',
      icon: 'cmdOpen16',
      variant: 'accent-high-contrast',
   },
};

export const colorStatusVariants = {
   info: 'bg-blue-600 border-blue-900',
   success: 'bg-green-500 border-green-900',
   warning: 'bg-yellow-500 border-yellow-900',
   error: 'bg-red-500 border-red-900',
};
export const statusCollection = {
   warning: [
      computeTaskUnifiedStatus.Terminated,
      computeTaskUnifiedStatus.Terminating,
      computeTaskUnifiedStatus.Deleting,
      computeTaskUnifiedStatus.LaunchPending,
      computeJobStatus.TimedOut,
   ],
   info: [
      computeTaskUnifiedStatus.Starting,
      computeTaskUnifiedStatus.Executing,
      computeTaskUnifiedStatus.Pending,
      computeTaskUnifiedStatus.Queued,
      computeTaskUnifiedStatus.Creating,
      computeTaskUnifiedStatus.Uploading,
      computeTaskUnifiedStatus.Submitting,
      computeJobStatus.Running,
   ],
   error: [
      computeTaskUnifiedStatus.Error,
      computeTaskUnifiedStatus.Failed,
      computeTaskUnifiedStatus.StartFailed,
      computeTaskUnifiedStatus.Interrupted,
      computeJobStatus.ServiceTerminated,
      computeJobStatus.InsufficientCredits,
   ],
   success: [computeTaskUnifiedStatus.Complete, computeJobStatus.Done],
};

// Represent the weight of a job when comparing updates to determine which to keep, highest remains, default 0
export const statusOrder = {
   [computeTaskUnifiedStatus.Pending]: 0,
   [computeTaskUnifiedStatus.Creating]: 1,
   [computeTaskUnifiedStatus.Uploading]: 2,
   [computeTaskUnifiedStatus.Submitting]: 3,
   [computeTaskUnifiedStatus.Queued]: 4,
   [computeTaskUnifiedStatus.Starting]: 5,
   [computeTaskUnifiedStatus.Executing]: 5,
   [computeJobStatus.Running]: 5,
   [computeTaskUnifiedStatus.Terminating]: 6,
   [computeTaskUnifiedStatus.Terminated]: 7,
   [computeTaskUnifiedStatus.Complete]: 7,
   [computeTaskUnifiedStatus.Failed]: 7,
   [computeTaskUnifiedStatus.Interrupted]: 7,
   [computeTaskUnifiedStatus.StartFailed]: 7,
   [computeJobStatus.ServiceTerminated]: 7,
   [computeJobStatus.TimedOut]: 7,
   [computeJobStatus.Done]: 7,
   [computeJobStatus.InsufficientCredits]: 7,
   [computeTaskUnifiedStatus.Deleting]: 8,
   [computeTaskUnifiedStatus.Deleted]: 9,
};

export const remoteSessionDefaults = {
   version: 2306.0001,
   idleTime: 12,
};
export const productBrand = 'Simcenter X';

export const upCaret = '\u23F6';
export const downCaret = '\u23F7';

export const fileTypes = [
   { type: 'files.types.image', validator: /\.(gif|jpe?g|tiff|png|webp|bmp|sce)$/i },
   { type: 'files.types.simulation', validator: /\.(sim|sim~|dmproj)$/i },
   { type: 'files.types.macro', validator: /\.(java)$/i },
   { type: 'files.types.log', validator: /\.(log|txt)$/i },
];

export const isAutomatedTest = window.navigator.webdriver || window.Cypress;
export const oneSecondInMs = 1000;

export const stepperTenses = {
   past: 'past',
   present: 'present',
   future: 'future',
};
export const millesondsInADay = 86400000;
export const validSTARCCMVersion = '2306';
export const supportedVersion = parseInt(validSTARCCMVersion);

export const fileExtensions = {
   simulationFile: '.sim',
   macro: '.java',
};

export const forbiddenContent = /[\\/:!"'*?|<>$&%]|(^(aux|clock\$|con|nul|prn|com\d|lpt\d)$)/i;

// Map to format old sim file versions to new representation
export const devV2marketingV = { 1: 2, 2: 2, 3: 6, 4: 6, 5: 10, 6: 10 };
export const fileStatus = {
   completed: 'completed',
   progressing: 'progressing',
};

export const dataStatePath = '/dataState';
export const precisions = ['mixed', 'double'];

// RUM required env vars and configs, error messages
export const requiredEnvVars = {
   rumIdentityPoolId: import.meta.env.VITE_RUM_IDENTITY_POOL_ID,
   rumEndpoint: import.meta.env.VITE_RUM_ENDPOINT,
   rumAppId: import.meta.env.VITE_RUM_APPLICATION_ID,
   rumAppVersion: import.meta.env.VITE_RUM_APPLICATION_VERSION,
   rumAppRegion: import.meta.env.VITE_RUM_APPLICATION_REGION,
};

export const rumTelemetries = ['http', 'performance', 'errors'];

export const rumErrors = {
   missingVars: 'Missing required environment variables',
   errorInitilize: 'Error initializing CloudWatch RUM web client',
};

// csa = Cloud Services Agreement
export const csa = {
   title: 'entitlement.title',
   name: 'entitlement.name',
   information: 'entitlement.information',
   id: 'accessModal',
   policyLink: 'https://www.sw.siemens.com/en-US/sw-terms/aup/',
   ok: 'entitlement.ok',
};

// job details
export const fileDescriptions = {
   image: {
      key: 'imgFile',
      label: 'Image',
      type: 'Image',
      extension: /\.(gif|jpe?g|tiff|png|webp|bmp|sce)$/i,
      checked: true,
   },
   sim: {
      key: 'simFile',
      label: 'Simulation',
      type: 'Simulation',
      extension: /\.(sim|sim~|dmproj)$/i,
      checked: true,
   },
   macro: {
      key: 'macroFile',
      label: 'Macro',
      type: 'Macro',
      extension: /\.(java)$/i,
      checked: true,
   },
   log: {
      key: 'logFile',
      label: 'Log',
      type: 'Log',
      extension: /\.(log|txt)$/i,
      checked: true,
   },
};

export const imageExtensionRegex = /(gif|jpe?g|tiff|png|webp|bmp|sce)$/i;
export const submittedFilesPath = '/submittedFiles';

export const hpcJobTemplate = {
   key: 'cloud_hpc',
   id: 'hpcJob',
   imageSrc: hpcJobIcon,
   dataCy: 'newCloudHPCJob',
};

export const remoteDesktopTemplate = {
   key: 'remote_desktop',
   id: 'remoteDesktop',
   imageSrc: remoteDesktopJobIcon,
   dataCy: 'newRemoteDesktop',
};
