/**
 * @typedef {import('@/types').Version} Version
 * @typedef {import('@/types').Application} Application
 */
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { hpcSubmissionStore } from '@/store/hpcJob/submission/store';
import { useStream } from '@/store/useStream';

import { isVersionDisabled, updateValue } from '@/utils/jobSubmission';

import { SwwcRadioButton, SwwcRadioGroup } from '@/swwc';

/**
 * Component that render the selectable list of precisions
 * @typedef {Object} VersionSelectorProps
 * @property {Version} version - selected version
 * @property {Application[]} applications - list of supported applications
 *
 * @param {VersionSelectorProps} prop
 * @returns {React.ReactElement}
 */
export function VersionSelector({ version, applications }) {
   const { t } = useTranslation();
   const state = useStream(hpcSubmissionStore.state);
   const versionError = path(['errors', 'version'], state);

   return (
      <SwwcRadioGroup orientation="vertical" variant="default" label={t('labels.version')}>
         {applications.map(({ marketingVersion }) => {
            return (
               <SwwcRadioButton
                  key={marketingVersion}
                  name="versionRadioGroup"
                  label={marketingVersion}
                  value={marketingVersion}
                  disabled={versionError || isVersionDisabled(marketingVersion, version.file)}
                  checked={marketingVersion === version.selected}
                  onCheckedChange={updateValue(hpcSubmissionStore.actions.setVersion)}
               />
            );
         })}
      </SwwcRadioGroup>
   );
}
