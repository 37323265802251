/**
 * @typedef {import('@/types').FileUploadError} FileUploadError
 */
import { useTranslation } from 'react-i18next';

import { isFileUploadFailed } from '@/utils/comparators';

import { SwwcButton, SwwcModalDialog } from '@/swwc';

/**
 * Modal Component displayed when upload of files fails
 * @typedef {Object} FileUploadFailModalProps
 * @property {import('@/types').FileUploadError} error
 * @property {() => void} onClose
 *
 * @param {FileUploadFailModalProps} props
 * @returns {React.ReactElement}
 */
export const FileUploadFailModal = ({ error, onClose }) => {
   const { t } = useTranslation();

   if (!isFileUploadFailed(error)) {
      return null;
   }

   return (
      <SwwcModalDialog
         dialogTitle={t('modal.FileUploadError.title')}
         isModal
         opened
         type="danger"
         className="absolute whitespace-pre"
         onOpenedChange={onClose}
      >
         <p>{t('modal.FileUploadError.message')}</p>
         <SwwcButton slot="actions" label={t('commands.close')} onClickEvent={onClose} />
      </SwwcModalDialog>
   );
};
