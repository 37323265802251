import { auth } from '@simcenter-x/store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

export function AuthContext({ children }) {
   const navigate = useNavigate();

   useEffect(() => {
      auth.initialize({ navigateFn: navigate });
   }, []);

   if (!auth.isLoggedIn.value) {
      return <LoadingIndicator className="h-screen flex justify-center items-center" />;
   }

   return children;
}
