// @ts-nocheck
import {
   always,
   any,
   compose,
   cond,
   includes,
   isEmpty,
   mergeDeepRight,
   path,
   prop,
   propSatisfies,
   split,
   T,
   trim,
   values,
} from 'ramda';

import { isNameInvalid } from '@/utils/comparators';
import { dataStates } from '@/utils/constants';

const getKey = compose(path(['2']), split('/'), prop('path'));
const checkProposedState = (form, proposal) => {
   const key = getKey(proposal);
   const value = path([key, 'value'])(form);

   if (key === 'name') {
      return mergeDeepRight(form, {
         [key]: { value, error: compose(isNameInvalid, trim)(value) },
      });
   }

   if (key === 'version') {
      return mergeDeepRight(form, { [key]: { value, error: isEmpty(value) } });
   }

   return mergeDeepRight(form, { [key]: { value } });
};
const hasError = any(prop('error'));
const hasEmptyValue = any(compose(isEmpty, prop('value')));
const handleDataState = (currentDataState) =>
   compose(
      cond([
         [hasError, always(dataStates.error)],
         [hasEmptyValue, always(currentDataState)],
         [T, always(dataStates.ready)],
      ]),
      values,
   );

const isUpdatingForm = propSatisfies(includes('/form'), 'path');

export const nap = ({ proposedState, proposal, currentState }) => {
   if (isUpdatingForm(proposal)) {
      const updatedState = checkProposedState(proposedState.form, proposal);
      return {
         dataState: handleDataState(currentState.dataState)(updatedState),
         form: updatedState,
         details: proposedState.details,
      };
   }
   return proposedState;
};
