import { useTranslation } from 'react-i18next';

import { useNavigateWithParams } from '@/modules/common/navigateWithParams';

import { SwwcButton } from '@/swwc';

const PageDoesntExist = () => {
   const { t } = useTranslation();
   const navigate = useNavigateWithParams();

   return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-70px)] text-center">
         <h1 className="text-3xl font-bold mb-4">{t('pageDoesntExist.title')}</h1>
         <p className="text-xl mb-8">{t('pageDoesntExist.description')}</p>
         <SwwcButton
            key={t('pageDoesntExist.buttonText')}
            type="button"
            label={t('pageDoesntExist.buttonText')}
            variant="accent-high-contrast"
            onClick={() => navigate('/')}
         />
      </div>
   );
};

export { PageDoesntExist };
