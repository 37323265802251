import { useNavigate } from 'react-router';

export const useNavigateWithParams = () => {
   const navigate = useNavigate();
   const navigateWithParams = (path, options = {}) => {
      const search = window.location.search;
      return navigate(`${path}${search}`, options);
   };
   return navigateWithParams;
};
