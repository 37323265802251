import { init } from '@module-federation/enhanced/runtime';
import { effect, signal } from '@preact/signals-core';
import { eqBy, prop, unionWith } from 'ramda';

import dev from './envs/dev.js';
import prd from './envs/prd.js';
import stg from './envs/stg.js';

const logStyle = 'color: #3296B9; font-weight: bold;';
const log = (x, ...args) => console.log(`%c [SCX] ${x}`, logStyle, ...args);

/**
 * @property {RemoteModuleDefinitionList} remotes
 */
export const remotes = signal(null);

effect(() => {
   log('Initialize remotes', remotes.value);
   init({
      name: '@simcenter-x/host',
      remotes: remotes.value,
   });
});

const mergeByName = (remotes) => (overrides) => unionWith(eqBy(prop('name')), overrides, remotes);

const queryParam = 'remoteOverrides';

export function getOverrides() {
   const urlParams = new URLSearchParams(window.location.search);
   log('Search', urlParams.get(queryParam));
   return urlParams.get(queryParam);
}

function getDevRemotes() {
   log('Fetch remotes');
   const overrides = getOverrides();
   if (overrides) {
      return fetch(overrides)
         .then((response) => response.json())
         .then(mergeByName(dev))
         .catch((error) => {
            log('Error fetching remote overrides', error);
            log('Using default dev remotes');
            return dev;
         })
         .then((mergedRemotes) => {
            remotes.value = mergedRemotes;
            return mergedRemotes;
         });
   }
   remotes.value = dev;
   return Promise.resolve(dev);
}

/**
 * @returns {Promise<RemoteModuleDefinitionList>}
 */
export const initRemotes = () => {
   switch (import.meta.env.VITE_DEPLOYMENT_TYPE) {
      case 'prd':
         remotes.value = prd;
         return Promise.resolve(prd);
      case 'stg':
         remotes.value = stg;
         return Promise.resolve(stg);
      default:
         return getDevRemotes();
   }
};
