import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

import { creditStore, hasBalance } from '@/store/credits/state';

import { dataStates } from '@/utils/constants';

import { LoadingIndicator } from './LoadingIndicator';

/**
 * Component to display the credits consumption estimate or error before submitting a job/session
 * @typedef {Object} CreditsHandlerProps
 * @property {number} hourlyCreditRate - the consumed credits per hour
 * @property {string} type - the type of the job/session
 *
 * @param {CreditsHandlerProps} props
 * @returns {React.ReactElement}
 */
export const CreditsHandler = ({ hourlyCreditRate, type }) => {
   const { t } = useTranslation();
   const { credits, dataState } = creditStore.value;

   if (dataState === dataStates.loading && isNil(credits)) {
      return <LoadingIndicator size="xxsmall" />;
   }

   if (dataState === dataStates.error) {
      return <p className="text-red-500">{t('credits.error')}</p>;
   }

   return hasBalance.value ? (
      <p className="text-lg font-medium text-black-800">
         {t('credits.consumptionEstimate', {
            value: hourlyCreditRate,
         })}
      </p>
   ) : (
      <p className="text-red-500">{t('credits.lackingAmount', { type })}</p>
   );
};
