import { SwwcDropdown } from '@com.siemens.plm.horizon/swwc/bindings/react';
import { compose, map, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { creditStore } from '@/store/credits/state';
import { store } from '@/store/listing/state';
import { remotes } from '@/store/remotes';

import { useNavigateWithParams } from '@/modules/common/navigateWithParams';

import { dataStates, hpcJobTemplate, remoteDesktopTemplate } from '@/utils/constants';

import { SwwcButton } from '@/swwc';

const buildOptionList = map(({ name }) => ({ label: name, value: name }));

const getEventValue = path(['detail', 'value', '0']);

/**
 * Button to create a new job of the given type
 * @typedef {Object} ButtonProps
 * @property {string} key - the jobType key for translation text
 * @property {string} id - the jobType ID
 * @property {string} dataCy - Attribute to facilitate targetting in tests
 *
 * @param {ButtonProps} props
 * @returns {React.ReactElement}
 */
const Button = ({ key, id, dataCy }) => {
   const { dataState } = creditStore.value;
   const navigate = useNavigateWithParams();
   const { t } = useTranslation();
   const jobType = t(`jobTypeName.${key}`);

   return (
      <SwwcButton
         key={jobType}
         type="button"
         label={jobType}
         variant="accent-high-contrast"
         icon="cmdAdd13"
         onClick={() => {
            navigate(id);
            store.actions.resetCurrentPage();
         }}
         data-cy={dataCy}
         disabled={dataState === dataStates.error || dataState === dataStates.migrating}
      />
   );
};

export const AppsButtons = () => {
   const navigate = useNavigateWithParams();

   if (remotes.value?.length) {
      return (
         <>
            <SwwcDropdown
               placeholder="Open Application"
               options={buildOptionList(remotes.value)}
               onValueChange={compose(navigate, getEventValue)}
            />
            {[hpcJobTemplate, remoteDesktopTemplate].map(Button)}
         </>
      );
   }
   return [hpcJobTemplate, remoteDesktopTemplate].map(Button);
};
