/**
 * Builds various API URLs based on region/auth information
 */
import { auth } from '@simcenter-x/store';
import { compose, curry, identity, isNotNil, path, prop, tryCatch } from 'ramda';

const appServerFromStateParam = compose(prop('appserver'), tryCatch(JSON.parse, identity));

/**
 * If an appserver is provided as query param, it will be present as such in the url on the first access.
 * Then after navigating to the auth and being redirected back, it won't be anymore and needs to be accessible
 * via the state search param.
 * If at this point the appserver information is in neither param, we use the default appserver based on the region.
 * Params should be the URL search params
 */
export const getAppServerFromParams = (params) => {
   const appserver = params.get('appserver');
   const state = params.get('state');

   if (isNotNil(appserver)) {
      return appserver;
   }

   return appServerFromStateParam(state);
};

export const getAppServerUrlFromState = compose(prop('appServerUrl'), () => auth.state.value);
export const getApiUrlFromState = compose(
   (url) => `${url}/${import.meta.env.VITE_GQL_SUFFIX}`,
   getAppServerUrlFromState,
);

export const getNiceDCVUrlFromState = compose(prop('niceDCVUrl'), () => auth.state.value);

export function getFullDomain(region, domain, prefix) {
   try {
      const domains = JSON.parse(domain);
      return `${prefix}${domains[region]}`;
   } catch {
      throw new Error(`Could not parse domain ${domain}`);
   }
}

const getRegion = path(['accessTokenData', 'sws.samauth.tiers', 0, 'region']);

const makeAppServerEndpoint = (endpoint) => `${import.meta.env.VITE_APP_SERVER_PREFIX}${endpoint}`;

export const makeEndpoints = curry((params, data) => {
   const appserver = getAppServerFromParams(params);

   const niceDCVUrl = getFullDomain(
      getRegion(data),
      import.meta.env.VITE_NICEDCV_GATEWAY_DOMAIN,
      import.meta.env.VITE_APP_SERVER_PREFIX,
   );

   if (appserver) {
      auth.setData({
         appServerUrl: makeAppServerEndpoint(appserver),
         niceDCVUrl,
      });
   } else {
      const appServerUrl = getFullDomain(
         getRegion(data),
         import.meta.env.VITE_APP_SERVER_DOMAIN,
         import.meta.env.VITE_APP_SERVER_PREFIX,
      );
      auth.setData({ appServerUrl, niceDCVUrl });
   }
   return auth.state.value;
});
