import { signal } from '@preact/signals-core';
import { compose, lensProp, set, view } from 'ramda';

import { dataStates } from '@/utils/constants';

import services from './services';

const model = {
   userFeatureFlags: [],
   state: dataStates.loading,
};

const flagsSignal = signal(model);

const stateLens = lensProp('state');
const flagsLens = lensProp('userFeatureFlags');

const setFlags = compose(set(stateLens, dataStates.ready), set(flagsLens));
const setError = set(stateLens, dataStates.error);

export const featureFlags = {
   load: (client) =>
      services
         .getFeatureFlags(client)
         .then((flags) => {
            flagsSignal.value = setFlags(flags, flagsSignal.value);
         })
         .catch(() => {
            flagsSignal.value = setError(flagsSignal.value);
         }),

   clear: () => {
      flagsSignal.value = model;
   },

   viewState: () => view(stateLens, flagsSignal.value),
   viewFlags: () => view(flagsLens, flagsSignal.value),
};
