/**
 * This file is used to define the remote modules that will be loaded by the application.
 * Microfrontend developers (i.e. Simcenter X App developers) should add their remote modules here.
 */
import { Route } from 'react-router';

import { RemoteComponent, RemoteModule } from './RemoteModule';

const makeRemoteComponent = (remote) =>
   remote.isReact ? (
      <RemoteComponent modulePath={`${remote.name}/${remote.exposedModule}`} />
   ) : (
      <RemoteModule modulePath={`${remote.name}/${remote.exposedModule}`} name={remote.element} />
   );

/**
 * Constructs routes for the list of remote modules.
 * @param { object } props
 * @param {RemoteModuleDefinitionList} props.remotes - The list of remote modules to load.
 * @returns {Array<JSX.Element>}
 */
export function remoteRoutes(remotes) {
   return remotes.reduce(
      (acc, remote) =>
         remote.excludeFromRoutes
            ? acc
            : acc.concat(<Route key={remote.name} path={remote.name} element={makeRemoteComponent(remote)} />),
      [],
   );
}
