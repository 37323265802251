// @ts-nocheck
import { includes, path } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { hasBalance } from '@/store/credits/state';
import { jobTypesSignal } from '@/store/jobtypes/store';
import { remoteDesktopStore } from '@/store/remoteDesktop/store';
import { useStream } from '@/store/useStream';

import { CreditsHandler } from '@/modules/common/CreditsHandler';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { TextInput } from '@/modules/common/TextInput';
import { useNavigateWithParams } from '@/modules/common/navigateWithParams';
import { Credits } from '@/modules/credits/Credits';

import { getHourlyCreditRate, getRDVersion } from '@/utils/accessors';
import { dataStates, remoteDesktopTemplate } from '@/utils/constants';
import { getTimeSpentInSeconds } from '@/utils/utils';

import { SwwcButton, SwwcInputField } from '@/swwc';

const onNameChange = (value) => {
   remoteDesktopStore.actions.changeFormValue({
      key: 'name',
      value,
   });
};

export function SubmissionForm() {
   const { t } = useTranslation();
   const client = useClient();
   const navigate = useNavigateWithParams();
   const { form, dataState } = useStream(remoteDesktopStore.state);
   const jobTypes = jobTypesSignal.value;
   const version = getRDVersion(jobTypes.applications);

   const [isSubmitted, setIsSubmitted] = useState(false);

   const onCancel = () => navigate('/');

   // Analytics variable to calculte time spent to fill the form before submitting it
   const timerStart = useMemo(() => Date.now(), []);

   useEffect(() => {
      remoteDesktopStore.actions.safeSetTemplate(jobTypes.templates);

      if (version) {
         remoteDesktopStore.actions.initializeVersion(version);
      }

      return remoteDesktopStore.actions.clear;
   }, [jobTypes.applications]);

   const onSubmit = () => {
      setIsSubmitted(true);
      remoteDesktopStore.actions
         .runSessionWorkflow(
            client,
            form,
            jobTypes.applications.remote_desktop,
            getTimeSpentInSeconds(timerStart, Date.now()),
         )
         .then((sessionId) => {
            const isSessionFormPage = includes(remoteDesktopTemplate.id, window.location.pathname);
            if (isSessionFormPage) {
               navigate(sessionId);
            }
         });
   };
   const hourlyCreditRate = getHourlyCreditRate(
      jobTypes.templates,
      path(['submissionTemplate', 'value', 'name'], form),
   );

   const submissionIsDisabled = !hasBalance.value || isSubmitted || dataState !== dataStates.ready;

   return (
      <div>
         <div className="flex justify-end">
            <Credits />
         </div>
         <form className="p-4 max-w-2xl m-auto lg:shadow-md flex flex-col gap-4">
            <span className="text-lg font-bold" slot="content">
               {t('common.newRDSession')}
            </span>
            <div className="flex gap-4 sm:gap-12 flex-col w-full">
               <div className="flex flex-col gap-4 sm:w-[60%]">
                  <TextInput
                     label={t('remote.form.sessionName')}
                     placeholder={t('remote.form.placeholder')}
                     inputValue={form.name.value}
                     onChange={onNameChange}
                     error={form.name.error}
                     errorMessage={t('remote.form.error.sessionName')}
                  />
                  <SwwcInputField
                     label={t('remote.form.idleTime')}
                     value={t('job.runTime.hours', { count: parseInt(form.idleTime) })}
                     readonly
                  />
                  <SwwcInputField
                     label={t('remote.form.simVersion')}
                     value={jobTypes.dataState === dataStates.loading ? t('common.loading') : form.version.value}
                     readonly
                  />
                  <SwwcInputField
                     label={t('remote.form.submissionTemplate.label')}
                     value={
                        jobTypes.dataState === dataStates.loading
                           ? t('common.loading')
                           : t('remote.form.submissionTemplate.value', { rate: hourlyCreditRate })
                     }
                     readonly
                  />
               </div>
               <div className="flex flex-col sm:items-end">
                  <div className="h-6 pb-6">
                     <CreditsHandler hourlyCreditRate={hourlyCreditRate} type={t('jobTypeName.remote_desktop')} />
                  </div>
                  <div className="flex gap-4 justify-end">
                     <SwwcButton label={t('commands.cancel')} size="md" onClick={onCancel} />
                     {isSubmitted && <LoadingIndicator size="small" />}
                     <SwwcButton
                        label={t(isSubmitted ? 'status.PENDING' : 'commands.startRemote')}
                        disabled={submissionIsDisabled}
                        variant="accent-high-contrast"
                        size="md"
                        onClick={onSubmit}
                     />
                  </div>
               </div>
            </div>
         </form>
      </div>
   );
}
