import { signal } from '@preact/signals-core';
import { compose, set } from 'ramda';

import services from '@/store/listing/services';
import { setError, setLoading, setReady } from '@/store/signals/dataStateUpdaters';

import { applicationsLens, mappingsLens, templatesLens } from './lenses';
import { model } from './model';
import { marshalSystemJobTemplates } from './utils';

export const jobTypesSignal = signal(model);

export const jobTypesStore = {
   getSystemJobTemplates: (client) => {
      jobTypesSignal.value = setLoading(jobTypesSignal.value);

      return services
         .getSystemJobTemplates(client)
         .then(marshalSystemJobTemplates)
         .then(({ templates, applications, mappings }) => {
            jobTypesSignal.value = compose(
               setReady,
               set(templatesLens, templates),
               set(applicationsLens, applications),
               set(mappingsLens, mappings),
            )(jobTypesSignal.value);
         })
         .catch(() => {
            jobTypesSignal.value = setError(jobTypesSignal.value);
         });
   },

   clear: () => {
      jobTypesSignal.value = model;
   },
};
