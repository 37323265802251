// @ts-nocheck
import { compose, concat, prop, propEq, reduce, reject, uniqBy } from 'ramda';

const kvArrayToObject = reduce((acc, cur) => {
   acc[cur.key] = cur.value;
   return acc;
}, {});

const insertApplication = (mappings, version, precision, type, typeHint, arr, computeTypes) => {
   arr.push({
      marketingVersion: parseFloat(mappings[version]),
      version,
      type,
      typeHint,
      precision: [precision],
      computeTypes,
   });
   return arr;
};

const updateApplication = (precision, obj) => {
   obj.precision.push(precision);
   return obj;
};

/**
 *
 * @param {{ systemJobTemplates: Object, displayMappings: Object }} param0
 * @returns {{ applications: import('./model').jobTypeApplications, templates: Object, displayMappings: Object }}
 */
export const marshalSystemJobTemplates = ({ systemJobTemplates, displayMappings }) =>
   systemJobTemplates.reduce(
      (acc, cur) => {
         acc.templates[cur.jobType] = compose(
            reject(propEq('null', 'name')),
            uniqBy(prop('name')),
            concat(cur.computeTypes),
         )(acc.templates[cur.jobType] ?? []);

         if (!acc.applications[cur.jobType]) {
            acc.applications[cur.jobType] = [];
         }

         const computeTypes = reject(propEq('null', 'name'), cur.computeTypes);

         cur.applicationsSupported.reduce((prev, { version, precision, type, typeHint }) => {
            // limit to star-ccmp app
            if (type !== 'star_ccmp') {
               return acc;
            }
            const obj = prev.find(propEq(version, 'version'));
            if (obj) {
               return updateApplication(precision, obj);
            }
            return insertApplication(acc.mappings, version, precision, type, typeHint, prev, computeTypes);
         }, acc.applications[cur.jobType]);
         return acc;
      },
      { templates: {}, applications: {}, mappings: kvArrayToObject(displayMappings), systemJobTemplates },
   );
